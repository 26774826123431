<template>
  <div class="gate main-cnt">
    <div class="title">闸机列表</div>
    <div class="content">
      <common-table
        ref="gateTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.gateList"
        :filters="filters"
        :columns="tableColumns"
        @preview="videoPreview"
        @showQrcode="showQrcode"
        @statusChange="statusChange"
        @projectChange="projectChange"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('r_K8STVt4Xgz2jHxR6FJuWZLicOlhE') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增闸机</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑 闸机弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      title="新增闸机"
      width="55%"
      confirmText="确认新增"
      btnWidth="140px"
      top="20vh"
      @wConfirm="confirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="project">
          <el-select
            v-model="ruleForm.dg_pid"
            clearable
            placeholder="请选择所属项目"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属景区" prop="s_id">
          <el-select
            v-model="ruleForm.s_id"
            :disabled="!ruleForm.dg_pid ? true : false"
            clearable
            placeholder="请选择所属景区"
          >
            <el-option
              :label="item.s_name"
              :value="item.s_id"
              v-for="item in scenicOptions"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属入口" prop="dg_seid">
          <el-select
            v-model="ruleForm.dg_seid"
            :disabled="!ruleForm.s_id ? true : false"
            clearable
            placeholder="请选择所属入口"
          >
            <el-option
              :label="item.se_name"
              :value="item.se_id"
              v-for="item in scenicEntranceOptions"
              :key="item.se_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="闸机名称" prop="dg_name">
          <el-input
            v-model="ruleForm.dg_name"
            placeholder="请输入闸机名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="控制机序号" prop="dg_sn">
          <el-input
            v-model="ruleForm.dg_sn"
            placeholder="请输入控制机序号"
          ></el-input>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 监控视频 弹框 -->
    <w-dialog
      ref="videoDialog"
      class="video-dialog"
      title="入口/车场名称-闸机名称"
      width="60%"
      btnWidth="140px"
      top="10vh"
      :hideFooter="true"
    >
      <video src="/i/movie.ogg" controls="controls">
        your browser does not support the video tag
      </video>
    </w-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { ElMessage } from "element-plus";
import { BasicApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import { checkStr } from "@/utils/common.js";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "search",
    name: "keyword",
    value: "",
    placeholder: "搜索闸机编号、入口或车场名称",
  },
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "s_id",
    value: "",
    placeholder: "请选择景区",
    options: [],
    val: "s_id",
    lab: "s_name",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "dg_id",
    label: "序号",
    minWidth: 60,
    color: "--text-third-color",
  },
  {
    prop: "entrance",
    objKey: "se_name",
    label: "所属入口",
    type: "obj",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "dg_name",
    label: "闸机名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "dg_online",
    label: "在线状态",
    minWidth: 120,
    color: "--text-color",
    type: "option",
    metaData: ["", "待接入", "在线", "离线"],
    colorData: ["", "#FF3333", "#67C23A", "#cccccc"],
  },
  {
    prop: "dg_sn",
    label: "控制机序号",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "dg_ip",
    label: "设备IP地址",
    minWidth: 120,
    color: "--text-color",
  },

  {
    type: "switch",
    prop: "dg_status",
    label: "启用状态",
    minWidth: 100,
    token: "r_90IpKeAWFmiuGhYNXUwMocPs7C1B",
  },
]);
/** 闸机表格对象 */
const gateTable = ref(null);
/** 视频预览 */
const videoPreview = (row) => {
  console.log(row);
  videoDialog.value.show();
};
/** 扫码查看 */
const showQrcode = (row) => {
  console.log(row);
};
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    dg_id: row.dg_id,
    dg_status: row.dg_status == 1 ? 2 : 1,
  };
  BasicApi.gateStatusUpdate(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("启用状态修改成功！");
      // 重新获取套餐列表数据
      gateTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "启用状态修改失败！";
      ElMessage.error(msg);
    }
  });
};

/** 项目改变处理 */
const projectChange = (row) => {
  filters.value[2].value = "";
  filters.value[2].options = [];
  // 获取闸机表格数据
  gateTable.value.tableLoad();
  if (row.value) {
    // 获取景区数据
    BasicApi.getScenicByProject({ pid: row.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

/** 新增弹框 */
const addDialog = ref(null);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = ref({
  s_id: "", // 景区
  dg_pid: "", //项目
  dg_seid: "", //入口
  dg_name: "", // 闸机名称
  dg_sn: "", // 控制机序号
});
const scenicOptions = ref([]);
watch(
  () => ruleForm.value.dg_pid,
  (v) => {
    if (v) {
      ruleForm.value.s_id = "";
      BasicApi.getScenicByProject({ pid: v }).then((res) => {
        scenicOptions.value = res.Data;
      });
    }
  },
  {
    deep: true,
  }
);
const scenicEntranceOptions = ref([]);

watch(
  () => ruleForm.value.s_id,
  (v) => {
    if (v) {
      ruleForm.value.dg_seid = "";
      BasicApi.getEntranceByScenic({ se_sid: v }).then((res) => {
        scenicEntranceOptions.value = res.Data;
      });
    }
  },
  {
    deep: true,
  }
);
/** 表单规则对象 */
const rules = reactive({
  s_id: [
    {
      required: true,
      message: "请选择所属景区",
      trigger: "change",
    },
  ],
  dg_pid: [
    {
      required: true,
      message: "请选择所属项目",
      trigger: "change",
    },
  ],
  dg_seid: [
    {
      required: true,
      message: "请选择所属入口",
      trigger: "change",
    },
  ],
  dg_name: [
    {
      required: true,
      message: "请选择闸机",
      trigger: "change",
    },
  ],
  dg_sn: [
    {
      required: true,
      message: "控制机序号",
      trigger: "blur",
    },
  ],
});
/** 弹出 新增闸机 弹框 */
const showAddDialog = () => {
  addDialog.value.show();
  ruleForm.value = {
    s_id: "", // 景区
    dg_pid: "", //项目
    dg_seid: "", //入口
    dg_name: "", // 闸机名称
    dg_sn: "", // 控制机序号
  };
};

/** 视频预览弹框 */
const videoDialog = ref(null);
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[1].options = res.Data ? res.Data : [];
      projectOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};
function confirm() {
  addDialog.value.isLoading = true;
  if (ruleForm.value.dg_sn && !checkStr(ruleForm.value.dg_sn, "letterNumber")) {
    ElMessage.warning({
      message: "控制机序号必须是字母和数字！",
    });
    addDialog.value.isLoading = false;
    return false;
  }
  const parmas = {
    dg_pid: ruleForm.value.dg_pid, //项目
    dg_seid: ruleForm.value.dg_seid, //入口
    dg_name: ruleForm.value.dg_name, // 闸机名称
    dg_sn: ruleForm.value.dg_sn, // 控制机序号
  };

  BasicApi["gateAdd"](parmas).then((res) => {
    addDialog.value.isLoading = false;
    if (res.Code === 200) {
      ElMessage.success(`操作成功！`);
      addDialog.value.close();
      gateTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : `操作失败！`;
      ElMessage.error(msg);
    }
  });
}
onMounted(() => {
  // 获取项目数据
  getProjectData();
  // 获取闸机表格数据
  gateTable.value.tableLoad();
});
</script>

<style lang="scss">
.gate {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 10px 15px 50px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
            }
          }
          .el-form-item.upload {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .video-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 20px;
        video {
          width: 100%;
          height: 70vh;
        }
      }
    }
  }
}
</style>
